import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import qs from "query-string"

import { useAccountContext } from "../../../context/AccountContext"
import mq from "../../../utils/mq"
import LoadingSpinner from "../../ui/LoadingSpinner"
import Button from "../../new-ui/button"
import InputField from "../../new-ui/input-field"

const Container = styled.div`
  ${mq({
    paddingLeft: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
    paddingRight: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
  })};
  ${mq({
    paddingTop: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
    paddingBottom: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
  })};
  display: flex;
  min-height: 564px;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const BtnLink = styled(Link)`
  display: block;
  padding-top: 1rem;
  text-decoration: none;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
`

const Form = styled.div`
  position: relative;
  width: 100%;
`

const NewPassword = () => {
  const account = useAccountContext()
  const [password, setPassword] = useState("")
  const [confirm, setConfirm] = useState("")
  const [matching, setMatching] = useState(false)
  const [done, setDone] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (confirm && password === confirm) {
      setMatching(true)
    } else {
      setMatching(false)
    }
  }, [password, confirm])

  const handleReset = () => {
    const { email, rpt } = qs.parse(window.location.search)
    setLoading(true)
    account
      .setPassword({ email, password, token: rpt })
      .then(() => {
        setDone(true)
      })
      .catch((err) => {
        setError(err.message)
        setLoading(false)
      })
  }

  return (
    <Container>
      <Body>
        {!done && "Fill out the form below to reset your password"}
        <Form>
          {done ? (
            <>
              Your password has been reset.
              <BtnLink to="/">
                <Button>Return to home</Button>
              </BtnLink>
            </>
          ) : (
            <>
              {loading && <LoadingSpinner />}
              {error && <div>{error}</div>}
              <InputField
                type="password"
                placeholder={"New Password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputField
                type="password"
                pb="1rem"
                placeholder={"Confirm Password"}
                value={confirm}
                onChange={(e) => setConfirm(e.target.value)}
              />
              <Button disabled={!matching} onClick={handleReset}>
                Reset Password
              </Button>
            </>
          )}
        </Form>
      </Body>
    </Container>
  )
}

export default NewPassword
